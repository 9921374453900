html {
    font-size: 1px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    padding: 0;
}

#root {
    height: 100%;
}

div,
p,
table,
ul,
li,
span {
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


/* 滚动条宽度， width：对应竖滚动条的宽度  height：对应横滚动条的高度*/

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #ccc;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    /* 较少使用 */
    border-radius: 3px;
}


/*定义滑块 样式*/

::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: #666;
}

::-webkit-scrollbar {
    /* opacity: 0; */
    width: 0;
    /* height: 0; */
    /* color: transparent !important; */
}